/* Sizing */
$desktopResWidth: 800px;
$tabletResWidth: 700px;
$mobileResWidth: 600px;

/* Pallette */
$black: #000000;
$white: #ffffff;
$grey: #f8f7f7;

$primary: #016bb5;
$background: #f2f8fe;
$highliteFontColor: #64696f;
$backgroundTableColor: #e1e1e1;
$tableHighliteColor: #4299f7;

/* Messages */
$success: green;

/* Type Face */
$titleFont: Inter;
$tableFont: Rift;
@import url("https://fonts.google.com/specimen/Inter");
@import url("https://fonts.adobe.com/fonts/rift");

/* Containers */
/* Content Container */
$contentContainerBckGrd: transparent;
$contentContainerHeight: 600px;
$contentContainerPadding: 25px;

/* Divider */

$dividerShapeMargin: 0 auto;
$dividerShapeWidth: 75%;
$dividerShapeHeight: 10px;
$dividerShapeSkew: skew(-5deg);
$dividerShapeBckgrdImg: linear-gradient(to right, $primary, #0b80d4);
