@import "./variables.scss";

/**** Light theme PAGES ****/

body {
  background: $background;
  margin: 0;
  // background-image: url("../images/CenterLogo.png");
  // background-position: center center;
  // background-repeat: no-repeat;
  // background-size: 200px;
}

.appContainer {
  background-image: url("../images/CenterLogo.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 150px;
  height: 800px;
}

/* Header Container */

.headerContainer {
  height: 65px;
  padding-top: 5px;
  padding-left: 10px;
  background: $primary;
}

.headerContainer img {
  margin-top: auto;
  margin-left: 12px;
  padding-top: 12px;
  height: 30px;
  width: auto;
  float: left;
}

/* Content Container */

.contentContainer {
  margin: 0 auto;
  height: 100%;
  padding: $contentContainerPadding;
  background: $contentContainerBckGrd;
}

/* Footer Container */
.footerContainer {
  margin: 25px auto;
  height: 125px;
}

.footerContainer p {
  float: right;
  opacity: 0.4;
  padding-right: 25px;
  margin-top: 100px;
}

/* Card component */
.cardComponent {
  width: 100%;
  height: 350px;
  background: #fff;
   position: relative;
}

.cardComponent:hover {
  background: #e0f3ff;
}

// Typing Animation

// $slider-name: typing-slider;
// $slides: 3;
// $typing-duration: 0.95s;
// $tab-letters: 11 8 10; //number of characters in each paragraph
// $slider-font-size: 2.5rem;

// .typing-slider {
//   margin: 0 auto;
// }

// @keyframes cursor {
//   from,
//   to {
//     border-color: transparent;
//   }
//   50% {
//     border-color: black;
//   }
// }

// @keyframes typing {
//   from {
//     width: 100%;
//   }
//   90%,
//   to {
//     width: 0;
//   }
// }

// @keyframes slide {
//   #{100% / $slides} {
//     font-size: $slider-font-size;
//     letter-spacing: 3px;
//   }
//   to {
//     font-size: 0;
//     letter-spacing: 0;
//   }
// }

// .#{$slider-name} {
//   font-family: Consolas, monospace;
//   font-weight: bold;
//   text-align: center;
//   white-space: nowrap;
// }

// .#{$slider-name} p {
//     color: #2898CA;
//   position: relative;
//   display: inline;
//   font-size: 0;
//   text-transform: uppercase;
//   letter-spacing: 0;
//   animation: slide $typing-duration * $slides step-start;
// }

// .#{$slider-name} p::after {
//   content: "";
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   border-left: 3px solid black;
//   background-color: $background;
//   animation: typing $typing-duration infinite, cursor 2s infinite;
// }

// @each $letters in $tab-letters {
//   $i: index($tab-letters, $letters);
//   .#{$slider-name} p:nth-child(#{$i}) {
//     animation-delay: $typing-duration * ($i - 1);
//   }
// }
